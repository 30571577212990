import { isAxiosError } from 'axios';
import debounce from 'lodash.debounce';
import {
  type ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { useAuth } from '@gbs-monorepo-packages/auth';
import {
  BaseDropdown,
  BaseDropdownItem,
  BreadcrumbsComponent,
  Button,
  DEBOUNCE_TIME,
  DefaultDescription,
  ETypeFolder,
  FormModal,
  HighUserRoles,
  type IApiThrowsError,
  type IFolderDTO,
  INIT_PAGE,
  type ISort,
  Logger,
  NO_LIMIT_PAGE,
  PageContent,
  PageHeader,
  SearchBar,
  TableImageCell,
  formatUnknownErrorMessage,
  getRouteFrom,
  useBreadcrumbs,
  useCanDocuments,
  useToast,
} from '@gbs-monorepo-packages/common';

import PersonIcon from '../../assets/person.png';
import { ListDocument } from '../../components/ListDocuments';
import { FOLDER, FOLDERS } from '../../constants/RoutePaths';
import { useDocument } from '../../hooks/useDocuments';
import {
  type IDocumentDTO,
  type IRenameDocumentProps,
  type IUploadDocumentProps,
  deleteDocument,
  getPrivateDocument,
  renameDocument,
  uploadDocument,
} from '../../services/documentsFolder';
import {
  type ICreateFolderProps,
  type IUpdateFolderProps,
  createFolder,
  deleteFolder,
  updateFolder,
} from '../../services/folders';
import { AddFolderModal } from '../DocumentsFolders/components/AddFolder';
import { EditFolderModal } from '../DocumentsFolders/components/EditFolder';
import { AddDocumentModal } from './components/AddDocument';
import { EditDocumentModal } from './components/EditDocument';
import { ModalPreview } from './components/ModalPreview';
import {
  ArrowDown,
  ButtonContent,
  DropdownButtonContainer,
  IconContainer,
  MainContainer,
  Subtitle,
} from './styles';

export const Documents = (): JSX.Element => {
  const { clientId = '', folderId = '' } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { user } = useAuth();

  const {
    documents,
    document,
    setDocuments,
    listDocuments,
    loadingDocuments,
    paginationMeta,
    handleSetDocument,
    folder: selectedFolder,
    subFolders,
  } = useDocument();
  const folderRoute = getRouteFrom(FOLDER);
  const foldersBaseRoute = getRouteFrom(FOLDERS);
  const { breadcrumbs, addBreadcrumb, removeBreadcrumb } = useBreadcrumbs();

  const [loadingActions, setLoadingActions] = useState(false);
  const [search, setSearch] = useState('');
  const lastSearch = useRef(search);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [sortOrder, setSortOrder] = useState<ISort | null>(null);

  const [documentToDelete, setDocumentToDelete] = useState<IDocumentDTO | null>(
    null
  );
  const [openAddDocumentModal, setOpenAddDocumentModal] = useState(false);
  const [openEditDocumentModal, setOpenEditDocumentModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const isModalDocumentDeleteOpen = !!documentToDelete;

  const [folderToEdit, setFolderEdit] = useState<IFolderDTO | null>(null);
  const [folderDelete, setFolderDelete] = useState<IFolderDTO | null>(null);
  const [openAddFolderModal, setOpenAddFolderModal] = useState(false);
  const isModalSubFolderDeleteOpen = !!folderDelete;
  const clientIdRef = useRef(clientId);
  const [errorOnAccept, setErrorOnAccept] = useState('');

  const [documentBlobUrls, setDocumentBlobUrls] = useState<
    Record<number, string>
  >({});

  const TitlePageText = selectedFolder?.name ?? '';

  const folderTitle = useMemo(() => {
    if (!selectedFolder) return '';
    return selectedFolder?.typeFolder === ETypeFolder.PRIVATE ||
      selectedFolder?.typeFolder === ETypeFolder.RENEWAL
      ? 'Private GBS Documents'
      : 'Public Employee Documents';
  }, [selectedFolder]);

  const typeFolderDescription =
    selectedFolder?.typeFolder === ETypeFolder.PRIVATE ? 'private' : 'public';

  const userIsHighRole = useMemo(() => {
    return HighUserRoles.some((role) => user?.roles.includes(role));
  }, [user]);

  const DocumentHeaderColumns = useMemo(() => {
    const headersArray = [];

    headersArray.push({
      id: 'originalName',
      name: 'File Name',
      textAlign: 'start',
    });
    headersArray.push({
      id: 'name',
      name: 'Description',
      textAlign: 'start',
    });

    if (userIsHighRole) {
      headersArray.push({
        id: 'highlightedDate',
        name: 'Display on Dashboard',
        textAlign: 'center',
      });
    }

    headersArray.push({
      id: 'createdAt',
      name: 'Upload Date',
      textAlign: 'end',
    });
    headersArray.push({
      id: 'options',
      name: '',
      textAlign: 'center',
    });

    return headersArray;
  }, [userIsHighRole]);

  const { allowCreate } = useCanDocuments({
    userId: user?.id ?? 0,
    userIsHighRole,
    folderData: {
      id: selectedFolder?.id,
      userCreateId: selectedFolder?.accountId,
      typeFolder:
        (selectedFolder?.typeFolder as ETypeFolder) ?? ETypeFolder.PRIVATE,
    },
  });

  const toRouteSubFolder = useCallback(
    (folderId: number | string) =>
      generatePath(folderRoute, {
        clientId,
        typeFolder: typeFolderDescription,
        folderId,
      }),
    [clientId, folderRoute, typeFolderDescription]
  );

  const handleFolderRowClick = useCallback(
    (item: IFolderDTO) => {
      const route = toRouteSubFolder(item.id);
      addBreadcrumb({
        name: item.name,
        url: route,
      });
      navigate(route, { state: { from: pathname } });
    },
    [addBreadcrumb, navigate, pathname, toRouteSubFolder]
  );

  const handleSelectFolderEdit = useCallback(
    (item: IFolderDTO) => {
      setFolderEdit(item);
      setOpenEditDocumentModal(true);
    },
    [setFolderEdit]
  );

  const handleSelectFolderDelete = useCallback(
    (item: IFolderDTO) => {
      setFolderDelete(item);
    },
    [setFolderDelete]
  );

  const handleGoBack = useCallback(() => {
    const isPrivate =
      selectedFolder?.typeFolder === ETypeFolder.PRIVATE ||
      selectedFolder?.typeFolder === ETypeFolder.RENEWAL;
    if (isPrivate) {
      navigate(
        generatePath(foldersBaseRoute, { clientId, typeFolder: 'private' })
      );
      return;
    }
    navigate(
      generatePath(foldersBaseRoute, { clientId, typeFolder: 'public' })
    );
  }, [clientId, selectedFolder, foldersBaseRoute, navigate]);

  useEffect(() => {
    // go back to first screen
    if (clientIdRef.current !== clientId) {
      handleGoBack();
    }

    if (!breadcrumbs) return;
    if (breadcrumbs?.length === 0) {
      handleGoBack();
    }
  }, [breadcrumbs, clientId, handleGoBack, navigate]);

  const getDocuments = useCallback(
    async (
      folderId: string,
      page: number,
      limit: number,
      filter?: string,
      sort?: ISort | null
    ) => {
      try {
        await listDocuments({
          folderId,
          page,
          limit,
          filter: filter ?? '',
          sort: JSON.stringify(sort),
        });
      } catch (err) {
        Logger.debug('err: ', err);
        addToast({
          title: 'Error on load documents',
          description: DefaultDescription,
          styleType: 'error',
          dataCy: 'get-documents-error-toast',
          duration: 3000,
        });
      }
    },
    [addToast, listDocuments]
  );
  useEffect(() => {
    void getDocuments(folderId, INIT_PAGE, NO_LIMIT_PAGE, '', sortOrder);
  }, [folderId, getDocuments, sortOrder]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (search.trim() !== lastSearch.current) {
      const execSearch = () => {
        lastSearch.current = search.trim();
        setDocuments([]);
        searchDocuments(true);
      };

      if (search.trim() === '') {
        execSearch();
      } else {
        timer = setTimeout(execSearch, 2000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [search]);

  useEffect(() => {
    const debouncedGetFolders = debounce(() => {
      if (sortOrder) {
        void getDocuments(
          folderId,
          INIT_PAGE,
          NO_LIMIT_PAGE,
          search,
          sortOrder
        );
      }
    }, DEBOUNCE_TIME);

    debouncedGetFolders();

    return () => {
      debouncedGetFolders.cancel();
    };
  }, [folderId, getDocuments, search, sortOrder]);

  const searchDocuments = (searchByButton = false) => {
    if (!loadingDocuments || searchByButton) {
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);
      void getDocuments(
        folderId,
        pageAux + 1,
        NO_LIMIT_PAGE,
        search,
        sortOrder
      );
    }
  };

  const addDocument = useCallback(
    async (documents: IUploadDocumentProps[]): Promise<boolean> => {
      setLoadingActions(true);
      if (!folderId) return false;
      let result = false;

      try {
        const data = await uploadDocument(folderId, documents);

        if (Array.isArray(data) && data.length > 0) {
          addToast({
            title: 'Documents uploaded successfully',
            styleType: 'success',
            dataCy: 'success-toast',
            duration: 3000,
          });

          void getDocuments(
            folderId,
            INIT_PAGE,
            NO_LIMIT_PAGE,
            lastSearch.current
          );
          setOpenAddDocumentModal(false);
          setErrorOnAccept('');
          result = true;
        }
      } catch (err) {
        Logger.debug('err: ', err);
        if (
          isAxiosError(err) &&
          err.response !== undefined &&
          err.response.status === 400
        ) {
          setOpenAddDocumentModal(true);
          setErrorOnAccept(formatUnknownErrorMessage(err));
        } else {
          setErrorOnAccept('');
          setOpenAddDocumentModal(false);
          addToast({
            title: formatUnknownErrorMessage(err),
            styleType: 'error',
            dataCy: 'error-toast',
            duration: 3000,
          });
        }
      } finally {
        setLoadingActions(false);
      }
      return result;
    },
    [addToast, folderId, getDocuments]
  );

  const addFolder = useCallback(
    async (folder: ICreateFolderProps): Promise<boolean> => {
      let result = false;
      if (!loadingActions) {
        setLoadingActions(true);
        folder = {
          ...folder,
          folderRef: Number(folderId),
        };
        await createFolder(folder)
          .then(() => {
            addToast({
              title: 'Folder created',
              styleType: 'success',
              dataCy: 'success-toast',
              duration: 3000,
            });
            setOpenAddFolderModal(false);
            void getDocuments(
              folderId,
              INIT_PAGE,
              NO_LIMIT_PAGE,
              lastSearch.current
            );
            result = true;
          })
          .catch((error: IApiThrowsError) => {
            addToast({
              title: 'Error on creating folder',
              description:
                error.response?.data.error &&
                  error.response?.data.error.code >= 500
                  ? (DefaultDescription as string)
                  : error.response?.data.error.message,
              styleType: 'error',
              dataCy: 'error-toast',
              duration: 3000,
            });
          })
          .finally(() => {
            setLoadingActions(false);
          });
      }

      return result;
    },
    [loadingActions, folderId, addToast, getDocuments]
  );

  const editDocument = useCallback(
    (documentAux: IRenameDocumentProps) => {
      if (!loadingActions) {
        setLoadingActions(true);

        renameDocument(documentAux)
          .then(() => {
            addToast({
              title: 'Document updated',
              styleType: 'success',
              dataCy: 'success-toast',
              duration: 3000,
            });
            handleSetDocument(null);
            setOpenEditDocumentModal(false);
            void getDocuments(
              folderId,
              INIT_PAGE,
              NO_LIMIT_PAGE,
              lastSearch.current
            );
          })
          .catch((err) => {
            Logger.debug('err: ', err);

            let errorMsg = DefaultDescription;

            if (
              isAxiosError(err) &&
              err.response !== undefined &&
              err.response.status === 400
            ) {
              const errorData = err.response.data as {
                error: { message: string };
              };

              errorMsg = errorData.error.message;
            }

            addToast({
              title: errorMsg,
              styleType: 'error',
              dataCy: 'error-toast',
              duration: 3000,
            });
          })
          .finally(() => {
            setLoadingActions(false);
          });
      }
    },
    [loadingActions, addToast, getDocuments, folderId]
  );

  const editFolder = useCallback(
    async (folder: IUpdateFolderProps): Promise<boolean> => {
      let result = false;
      if (!loadingActions) {
        setLoadingActions(true);

        await updateFolder(folder)
          .then(() => {
            addToast({
              title: 'Folder updated',
              styleType: 'success',
              dataCy: 'success-toast',
              duration: 3000,
            });
            void getDocuments(
              folderId,
              INIT_PAGE,
              NO_LIMIT_PAGE,
              lastSearch.current
            );
            result = true;
          })
          .catch((error: IApiThrowsError) => {
            addToast({
              title: 'Error on updated folder',
              description:
                error.response?.data.error &&
                  error.response?.data.error.code >= 500
                  ? (DefaultDescription as string)
                  : error.response?.data.error.message,
              styleType: 'error',
              dataCy: 'error-toast',
              duration: 3000,
            });
          })
          .finally(() => {
            setLoadingActions(false);
          });
      }

      return result;
    },
    [addToast, folderId, getDocuments, loadingActions]
  );

  const deleteDocumentHandle = useCallback(() => {
    if (!documentToDelete) {
      return;
    }

    if (!loadingActions) {
      setLoadingActions(true);

      const documentId = String(documentToDelete.id);
      deleteDocument({ documentId })
        .then(() => {
          addToast({
            title: 'Document deleted',
            description: `Document was successfully deleted.`,
            styleType: 'success',
            dataCy: 'success-toast',
            duration: 3000,
          });
          void getDocuments(
            folderId,
            INIT_PAGE,
            NO_LIMIT_PAGE,
            lastSearch.current
          );
        })
        .catch(() => {
          addToast({
            title: 'Error on deleting document',
            description: DefaultDescription as string,
            styleType: 'error',
            dataCy: 'delete-document-error-toast',
            duration: 3000,
          });
        })
        .finally(() => {
          setDocumentToDelete(null);
          setLoadingActions(false);
        });
    }
  }, [documentToDelete, loadingActions, addToast, getDocuments, folderId]);

  const removeFolder = useCallback(() => {
    if (!folderDelete) {
      return;
    }

    if (!loadingActions) {
      setLoadingActions(true);

      deleteFolder({ id: folderDelete.id })
        .then(() => {
          addToast({
            title: 'Folder deleted',
            styleType: 'success',
            dataCy: 'success-toast',
            duration: 3000,
          });
          void getDocuments(
            folderId,
            INIT_PAGE,
            NO_LIMIT_PAGE,
            lastSearch.current
          );
        })
        .catch(() => {
          addToast({
            title: 'Error on deleting folder',
            description: DefaultDescription as string,
            styleType: 'error',
            dataCy: 'error-toast',
            duration: 3000,
          });
        })
        .finally(() => {
          setFolderDelete(null);
          setLoadingActions(false);
        });
    }
  }, [addToast, folderDelete, folderId, getDocuments, loadingActions]);

  const onClipboardCopy = useCallback(
    (item: IDocumentDTO) => {
      navigator.clipboard
        ?.writeText(item.path)
        .then(() => {
          addToast({
            title: 'Link copied',
            description: `Link of document ${item.name ?? item.originalName ?? 'unknown'
              } was successfully copied`,
            styleType: 'success',
            dataCy: 'success-toast',
          });
        })
        .catch((err) => {
          Logger.debug('err: ', err);

          addToast({
            title: "Error on copy documents's link",
            description: DefaultDescription as string,
            styleType: 'error',
            dataCy: 'error-toast',
          });
        });
    },
    [addToast]
  );

  const handleDeclineOnAddDocument = useCallback(() => {
    setOpenAddDocumentModal(false);
    setErrorOnAccept('');
  }, []);

  const handleDeclineDocument = useCallback(() => {
    handleSetDocument(null);
    setOpenEditDocumentModal(false);
    setDocumentToDelete(null);
  }, [handleSetDocument]);

  const handleDeclineSubFolder = useCallback(() => {
    setFolderEdit(null);
    setFolderDelete(null);
    setOpenAddFolderModal(false);
  }, []);

  const removeBreadcrumbPage = useCallback(
    (index: number) => {
      removeBreadcrumb(index);
    },
    [removeBreadcrumb]
  );

  const hasRequestInLoading = useMemo(() => {
    return loadingDocuments || loadingActions;
  }, [loadingDocuments, loadingActions]);

  const createBlobUrl = useCallback(
    async (document: IDocumentDTO) => {
      try {
        if (
          selectedFolder?.typeFolder === ETypeFolder.PRIVATE &&
          ['jpg', 'jpeg', 'png'].includes(document.extension || '')
        ) {
          const response = await getPrivateDocument({
            documentId: String(document.id),
          });
          const { data } = response;

          const extension = (document.extension || '').toLowerCase();
          let mimeType = 'image/jpeg';
          if (extension === 'png') {
            mimeType = 'image/png';
          }
          const blob = await (
            await fetch(`data:${mimeType};base64,${data}`)
          ).blob();
          const blobUrl = URL.createObjectURL(blob);

          setDocumentBlobUrls((prev) => {
            const existingUrl = prev[document.id];
            if (existingUrl) {
              URL.revokeObjectURL(existingUrl);
            }
            return { ...prev, [document.id]: blobUrl };
          });

          return blobUrl;
        }
        return document.path;
      } catch (error) {
        addToast({
          title: 'Error loading document preview',
          description: DefaultDescription,
          styleType: 'error',
          dataCy: 'error-toast',
          duration: 3000,
        });
        return document.path;
      }
    },
    [addToast, selectedFolder?.typeFolder]
  );

  const handleSelectDocumentDelete = useCallback(
    (document: IDocumentDTO) => {
      setDocumentToDelete(document);
      void createBlobUrl(document);
    },
    [setDocumentToDelete, createBlobUrl]
  );

  const handleSelectDocument = useCallback(
    (document: IDocumentDTO) => {
      handleSetDocument(document);
    },
    [handleSetDocument]
  );

  const handleSelectDocumentEdit = useCallback(() => {
    setOpenEditDocumentModal(true);
  }, []);

  const handleOpenPreviewModal = useCallback(() => {
    setOpenPreviewModal(true);
  }, []);

  const handleSetIsDropdownOpen = useCallback(
    (value: boolean) => {
      setIsDropdownOpen(value);
    },
    [setIsDropdownOpen]
  );

  useEffect(() => {
    return () => {
      Object.values(documentBlobUrls).forEach((url) => {
        URL.revokeObjectURL(url);
      });
    };
  });

  return (
    <MainContainer data-cy="document-container">
      <PageHeader
        title={TitlePageText}
        additionalActions={
          !allowCreate ? null : (
            <DropdownButtonContainer data-cy="add-button-container">
              <BaseDropdown
                onOpenChange={(isOpen) => {
                  setIsDropdownOpen(isOpen);
                }}
                disabled={hasRequestInLoading}
                customTrigger={
                  <Button
                    dataCy="button-document"
                    styleType="outline"
                    noPaddingRight
                  >
                    <ButtonContent>
                      <p>New</p>
                      <IconContainer>
                        <ArrowDown />
                      </IconContainer>
                    </ButtonContent>
                  </Button>
                }
              >
                <BaseDropdownItem
                  data-cy="button-create-folder"
                  onClick={() => {
                    setOpenAddFolderModal(true);
                  }}
                >
                  Create Folder
                </BaseDropdownItem>
                <BaseDropdownItem
                  data-cy="button-upload-document"
                  onClick={() => {
                    setOpenAddDocumentModal(true);
                  }}
                >
                  Upload Document
                </BaseDropdownItem>
              </BaseDropdown>
            </DropdownButtonContainer>
          )
        }
      />

      <Subtitle>{folderTitle}</Subtitle>
      <PageContent>
        <SearchBar
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value);
          }}
          onClearInput={() => {
            setSearch('');
          }}
          search={search}
          maxLength={50}
          placeholder="Search Document"
          loading={loadingDocuments}
          id="search-document"
        />
        <BreadcrumbsComponent
          links={breadcrumbs}
          onClick={removeBreadcrumbPage}
        />
        <ListDocument
          folders={subFolders}
          documents={documents}
          loading={loadingDocuments}
          paginationMeta={paginationMeta}
          search={searchDocuments}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          headerColumns={DocumentHeaderColumns}
          disabledActions={false}
          handleFolderClick={handleFolderRowClick}
          handleSelectFolderEdit={handleSelectFolderEdit}
          handleSelectFolderDelete={handleSelectFolderDelete}
          handleSetIsDropdownOpen={handleSetIsDropdownOpen}
          handleOnClipboardCopy={onClipboardCopy}
          handleSelectDocumentDelete={handleSelectDocumentDelete}
          handleSelectDocumentEdit={handleSelectDocumentEdit}
          handleSelectDocument={handleSelectDocument}
          handleOpenPreviewModal={handleOpenPreviewModal}
        />
      </PageContent>
      {openAddDocumentModal && (
        <AddDocumentModal
          onAccept={addDocument}
          onDecline={handleDeclineOnAddDocument}
          open={!isDropdownOpen && openAddDocumentModal}
          zIndex={10}
          errorOnAccept={errorOnAccept}
          loading={loadingActions}
        />
      )}

      {openEditDocumentModal && document && (
        <EditDocumentModal
          document={document}
          onAccept={editDocument}
          onDecline={handleDeclineDocument}
          open={!isDropdownOpen && useDocument !== null}
          loading={loadingActions}
          zIndex={10}
        />
      )}
      <FormModal
        dataCy="delete-document-dialog"
        acceptText="Confirm"
        declineText="Cancel"
        open={!isDropdownOpen && isModalDocumentDeleteOpen}
        loading={loadingActions}
        mainText={`Are you sure you want to delete?`}
        onAccept={deleteDocumentHandle}
        onDecline={handleDeclineDocument}
        onOpenChange={handleDeclineDocument}
        zIndex={10}
      >
        {documentToDelete && (
          <TableImageCell
            text={
              documentToDelete.name
                ? documentToDelete.name
                : documentToDelete.originalName ?? 'unknown'
            }
            dataCy="document-delete-modal-content"
            src={
              selectedFolder?.typeFolder === ETypeFolder.PUBLIC ||
                selectedFolder?.typeFolder === ETypeFolder.EDGE
                ? documentToDelete.path
                : documentBlobUrls[documentToDelete.id] ?? ''
            }
          />
        )}
      </FormModal>

      {openPreviewModal && document && (
        <ModalPreview
          open={openPreviewModal}
          dataCy="form-modal"
          setWidth="80vw"
          document={document}
          typeFolder={selectedFolder?.typeFolder ?? ETypeFolder.PRIVATE}
          onDecline={() => {
            setOpenPreviewModal(false);
            handleSetDocument(null);
          }}
          zIndex={10}
        />
      )}

      <AddFolderModal
        typeFolder={selectedFolder?.typeFolder ?? ETypeFolder.PUBLIC}
        onAccept={addFolder}
        onDecline={() => {
          setOpenAddFolderModal(false);
        }}
        open={!isDropdownOpen && openAddFolderModal}
        loading={loadingActions}
        zIndex={10}
      />

      {folderToEdit && (
        <EditFolderModal
          folder={folderToEdit}
          onAccept={editFolder}
          onDecline={handleDeclineSubFolder}
          open={!isDropdownOpen && folderToEdit !== null}
          loading={loadingActions}
          zIndex={10}
        />
      )}

      <FormModal
        dataCy="delete-folder-dialog"
        acceptText="Confirm"
        declineText="Cancel"
        open={!isDropdownOpen && isModalSubFolderDeleteOpen}
        loading={loadingActions}
        mainText={`Are you sure you want to delete?`}
        onAccept={removeFolder}
        onDecline={handleDeclineSubFolder}
        onOpenChange={handleDeclineSubFolder}
        zIndex={10}
      >
        {folderDelete && (
          <TableImageCell
            text={`${folderDelete.name}`}
            dataCy="folder-delete-modal-content"
            type="icon"
            src={PersonIcon}
          />
        )}
      </FormModal>
    </MainContainer>
  );
};
